<script>
import CustomersService from '@/service/customers.service';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

function parseTime(time) {
  return dayjs(`1999-09-09T${time}`).format('HH:mm');
}

export default {
  name: 'list-customers',

  components: {
    ModalRegisterCustomer: () => import('./ModalRegisterCustomer.vue'),
  },

  filters: {
    accessHour(val) {
      if (val.freeAccess) return 'Livre';
      if (val.startTime && val.endTime) {
        return `${parseTime(val.startTime)} - ${parseTime(val.endTime)}`;
      }
      return '---';
    },
  },

  data() {
    return {
      loading: false,
      options: {
        sortBy: ['name'],
        sortDesc: [false],
      },

      totalItems: 0,
      items: [],

      headers: [
        {
          text: 'Foto',
          align: 'center',
          sortable: false,
          value: 'avatar',
          width: 80,
        },
        { text: 'Nome', value: 'name' },
        { text: 'CPF', value: 'cpf' },
        { text: 'Horario de acesso', sortable: false, value: 'accessHour' },
        { text: 'Ações', align: 'right', sortable: false, value: 'actions' },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },

      deep: true,
    },
  },

  methods: {
    async fetch() {
      this.loading = true;

      try {
        const filter = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };

        if (this.options.sortBy.length > 0) {
          filter.sortBy = `${this.options.sortBy[0]}:${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`;
        }

        const { data, meta } = await CustomersService.find(filter);
        console.log(data);
        this.items = data;
        this.page = meta?.currentPage;
        this.totalItems = meta?.totalItems;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    create() {
      this.$refs.modalRegister.$emit('create');
    },

    edit(customer) {
      this.$refs.modalRegister.$emit('edit', customer.id);
    },

    remove(customer) {
      const textbyTypeUser = () => {
        let text = `Deseja remover '${customer.name}'?`;
        if (customer.student?.id) {
          text = text + ' Esse é o acesso de um ESTUDANTE. Remover o acesso removerá o cadastro do ESTUDANTE.';
        }

        if (customer.teacher?.id) {
          text =
            text +
            ' Esse é o acesso de um PROFESSOR. Se remover esse acesso também será removido o aluno. Utilize o campo acesso para bloquear sem excluir.';
        }

        return text;
      };

      this.$swal({
        text: textbyTypeUser(),
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        preConfirm: () => {
          return CustomersService.delete(customer.id);
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Removido!',
              text: 'Usuário removido com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetch();
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <v-row class="pb-3">
      <v-col cols="12" sm="4">
        <h5 class="pt-4">Acesso</h5>
      </v-col>
      <v-col cols="12" sm="8" class="text-right">
        <v-btn color="primary" @click="create">Novo</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 50] }"
      class="elevation-1 no-line-row"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <v-avatar v-if="item.avatarUrl" class="avatar-customer my-2" size="80">
          <img v-auth-image="item.avatarUrl" />
        </v-avatar>
      </template>
      <template v-slot:[`item.cpf`]="{ item }">
        <span>{{ item.cpf | cpf }}</span>
      </template>
      <template v-slot:[`item.accessHour`]="{ item }">
        <span>{{ item | accessHour }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-chip v-if="item.student?.id" x-small color="orange" outlined>ESTUDANTE</v-chip>
        <v-chip v-if="item.teacher?.id" x-small color="orange" outlined>PROFESSOR</v-chip>
        <v-btn icon title="Editar usuário" @click="edit(item)">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn icon title="Remover usuário" @click="remove(item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <modal-register-customer ref="modalRegister" @success="fetch" />
  </div>
</template>

<style scoped>
.avatar-customer {
  max-width: 80px !important;
  min-width: unset !important;
  width: unset !important;
}
</style>
