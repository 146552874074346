import api from './api';

class CustomersService {
  get(id) {
    return api.get(`/customers/${id}`).then((res) => res.data);
  }

  find(params) {
    return api.get('/customers', { params }).then((res) => res.data);
  }

  create(data) {
    return api.post(`/customers`, data).then((res) => res.data);
  }

  update(id, data) {
    return api.patch(`/customers/${id}`, data).then((res) => res.data);
  }

  delete(id) {
    return api.delete(`/customers/${id}`).then((res) => res.data);
  }
}

export default new CustomersService();
